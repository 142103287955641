import Swiper from 'swiper/bundle';

import 'swiper/css/bundle';

function checkStickyNavbar() {
  const navbar = document.querySelector('.navbar');
  var lastScrollTop = 80;
  var st = window.scrollY;
  if (st > lastScrollTop && 0 <= lastScrollTop) {
    navbar.classList.remove('navbar--transparent');
  } else {
    navbar.classList.add('navbar--transparent');
  }
}

window.addEventListener('load', function () {
  let headerSlider = document.querySelector('.header-slider__carousel');

  // sticky navbar
  if (document.body.classList.contains('has-sticky-navbar')) {
    checkStickyNavbar();

    window.addEventListener('scroll', function () {
      checkStickyNavbar();
    });
  }
  if (headerSlider) {
    // carousel
    headerSlider = new Swiper('.header-slider__carousel', {
      clickable: true,
      loop: true,
      draggable: true,
      autoplay: {
        delay: 4000,
      },
      a11y: {
        paginationBulletMessage: 'Aller à la diapositive {{index}}',
        nextSlideMessage: 'Prochaine diapositive',
        prevSlideMessage: 'Diapositive précédente',
      },
      pagination: {
        el: '.header-slider__carousel__navigation .swiper-pagination',
        dynamicBullets: true,
        clickable: true,
      },
    });
  }
});
