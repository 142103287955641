window.addEventListener('load', function () {
  const togglesWindow = document.querySelectorAll('.toggleSearchBtn');
  const closeWindow = document.querySelector('.closeSearchBtn');
  const searchWindow = document.getElementById('searchWindow');

  if (0 < togglesWindow.length) {
    togglesWindow.forEach(function (toggleWindow) {
      toggleWindow.addEventListener('click', function () {
        if (searchWindow.classList.contains('opened')) {
          searchWindow.classList.remove('opened');
          document.body.classList.remove('lockedPosition');
          document.documentElement.classList.remove('lockedPosition');
        } else {
          searchWindow.classList.add('opened');
          document.body.classList.add('lockedPosition');
          document.documentElement.classList.add('lockedPosition');
        }
      });
    });
  }

  if (closeWindow) {
    closeWindow.addEventListener('click', function () {
      searchWindow.classList.remove('opened');
      document.body.classList.remove('lockedPosition');
      document.documentElement.classList.remove('lockedPosition');
    });
  }
});
